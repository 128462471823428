<template>
    <div class="fast_entry box-bb flex height100 width100 flex-jc-fs cursor-df" id="fast_entry">
        <div class="title width100 flex flex-jc-fs flex-ai-c">
            <img :src="entry_src" />
            <span>快捷入口</span>
        </div>
        <div class="content width100">
            <div
                class="content_item flex flex-ai-c flex-jc-fs width100"
                v-for="(item, index) in list"
                :key="index"
                @click="fastTodo(item)"
            >
                <img :src="item.img" />
                <span>{{ item.text }}</span>
            </div>
        </div>
        <tableModel @addApproval="addApproval" ref="tableModel" />
    </div>
</template>
<script>
import tableModel from '@/views/fileStream/documentApproval/tableModel.vue'
export default {
    components: {
        tableModel,
    },
    data() {
        return {
            entry_src: require('@/assets/img/home/kjrk.png'),
            list: [
                {
                    text: '快速发文',
                    url: '',
                    img: require('@/assets/img/home/ksfw.png'),
                    route: { path: '/fileStreamDocumentSend', query: { todo: 'draftDocumentdeeee' } },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    text: '收文登记',
                    url: '',
                    img: require('@/assets/img/home/swdj.png'),
                    route: { path: '/fileStreamDocumentManage' },
                    storeKey: 'bill/setRegistration',
                },
                {
                    text: '拟稿发文',
                    url: '',
                    img: require('@/assets/img/home/qbng.png'),
                    route: { path: '/fileStreamDocumentSend', query: { todo: 'draftDocument' } },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    text: '发起审批',
                    url: '',
                    img: require('@/assets/img/home/fwng.png'),
                    todo: 'createApproval',
                },
                // {
                //   text: '文书归档',
                //   url: '',
                //   img: require('@/assets/img/home/filingOfDocuments.png'),
                //   route: { path: '/fileStreamArchived' }
                // }
            ],
        }
    },
    props: {},
    methods: {
        fastTodo(item) {
            if (item.todo === 'createApproval') {
                this.showModel()
                return
            }
            item.storeKey && this.$store.commit(item.storeKey, true)
            this.$router.push(item.route)
        },
        showModel() {
            this.$refs.tableModel.open()
        },
        addApproval(record) {
            this.$emit('approval', { type: 'approval', id: record.id })
            // this.$refs.fastEntry.open({ type: 'approval', id: record.id })
        },
    },
}
</script>
<style lang="less" scoped>
.fast_entry {
    background: #fff;
    border-radius: 3px;
    flex-direction: column;
    justify-content: flex-start !important;

    cursor: pointer;
    .title {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }
    .content {
        padding: 15px;
        .content_item {
            margin: 5px 0 15px;
            img {
                height: 50px;
                width: 50px;
            }
            span {
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 15px;
            }
        }
    }
}
</style>